<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Afíliate</h1>
                <p class="titulo-descripcion">
                    ¡Se parte de esta comunidad!
                </p>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container pt-5 pb-5 mb-5">
        <div class="row" style="position: relative;">
            <div class="imagen-contacto"></div>
            <div class="col-lg-6 col-md-8 offset-lg-6 offset-md-4">
                <form [formGroup]="AfiliateForm" (ngSubmit)="onSubmit()" class="row form-container">
                    <div class="col-12">
                        <p><q>Aprender sin reflexionar es malgastar la energía</q><br><small>Kun FuTse, Confucio</small> </p>
                    </div>
                    <div class="form-group col-12">
                        <label for="Nombre">Nombre</label>
                        <input formControlName="Nombre" class="form-control" type="text" [ngClass]="{ 'is-invalid': submitted && f['Nombre'].errors }"/>
                        <div *ngIf="submitted && f['Nombre'].errors" class="invalid-feedback">
                          <div *ngIf="f['Nombre'].errors['required']">El nombre es necesario</div>
                          <div *ngIf="f['Nombre'].errors['minlength']">El nombre debe de ser minimo de 3 caracteres</div>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="Email">Email</label>
                        <input formControlName="Email" class="form-control" type="email" [ngClass]="{ 'is-invalid': submitted && f['Email'].errors }"/>
                        <div *ngIf="submitted && f['Email'].errors" class="invalid-feedback">
                          <div *ngIf="f['Email'].errors['required']">Email es necesario</div>
                          <div *ngIf="f['Email'].errors['pattern']">Email es invalido</div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="Estado">Estado</label>
                        <select formControlName="Estado" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['Estado'].errors }">
                            <option value="Aguascalientes">Aguascalientes</option>
                            <option value="Baja California">Baja California</option>
                            <option value="Baja California Sur">Baja California Sur</option>
                            <option value="Campeche">Campeche</option>
                            <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                            <option value="Colima">Colima</option>
                            <option value="Chiapas">Chiapas</option>
                            <option value="Chihuahua">Chihuahua</option>
                            <option value="Ciudad de México">Ciudad de México</option>
                            <option value="Durango">Durango</option>
                            <option value="Guanajuato">Guanajuato</option>
                            <option value="Guerrero">Guerrero</option>
                            <option value="Hidalgo">Hidalgo</option>
                            <option value="Jalisco">Jalisco</option>
                            <option value="México">México</option>
                            <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
                            <option value="Morelos">Morelos</option>
                            <option value="Nayarit">Nayarit</option>
                            <option value="Nuevo León">Nuevo León</option>
                            <option value="Oaxaca">Oaxaca</option>
                            <option value="Puebla">Puebla</option>
                            <option value="Querétaro">Querétaro</option>
                            <option value="Quintana Roo">Quintana Roo</option>
                            <option value="San Luis Potosí">San Luis Potosí</option>
                            <option value="Sinaloa">Sinaloa</option>
                            <option value="Sonora">Sonora</option>
                            <option value="Tabasco">Tabasco</option>
                            <option value="Tamaulipas">Tamaulipas</option>
                            <option value="Tlaxcala">Tlaxcala</option>
                            <option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</option>
                            <option value="Yucatán">Yucatán</option>
                            <option value="Zacatecas">Zacatecas</option>
                        </select>
                        <div *ngIf="submitted && f['Estado'].errors" class="invalid-feedback">
                            <div *ngIf="f['Estado'].errors['required']">Estado es necesario</div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="FechaNacimiento">Fecha de nacimiento*</label>
                        <input formControlName="FechaNacimiento" type="date" placeholder="ej. 04/12/1993" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['FechaNacimiento'].errors }"/>
                        <div *ngIf="submitted && f['FechaNacimiento'].errors" class="invalid-feedback">
                          <div *ngIf="f['FechaNacimiento'].errors['required']">Fecha de nacimiento es necesaria</div>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="Telefono">Teléfono</label>
                        <ngx-intl-tel-input
                            [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }"
                            [cssClass]="'custom'" 
                            [enableAutoCountrySelect]="true" 
                            [enablePlaceholder]="true" 
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" 
                            [selectedCountryISO]="CountryISO.Mexico"
                            [maxLength]="15" 
                            [phoneValidation]="true" 
                            [separateDialCode]="separateDialCode"
                            searchCountryPlaceholder='Buscar'
                            name="phone" formControlName="phone">
                        </ngx-intl-tel-input>
                      <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback">
                        <div *ngIf="f['phone'].errors['required']">Número telefónico necesario</div>
                        <div *ngIf="f['phone'].errors">Número telefónico invalido</div>
                      </div>
                    </div>
                    <div class="form-check col-12">
                        <input type="checkbox" formControlName="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f['acceptTerms'].errors }"/>
                        <div *ngIf="submitted && f['acceptTerms'].errors" class="invalid-feedback">
                            Acepta el aviso de privacidad
                        </div>
                        <label class="form-check-label" for="acceptTerms">Estoy de acuerdo con el <a routerLink="/aviso-de-privacidad" target="_blank" > Aviso de privacidad</a></label>
                    </div>
                    <div class="col-12 text-center">
                        <div class="msj-form msj-enviando" *ngIf="msjenvio">
                            <img src="assets/images/loading.gif">
                        </div>
                        <div class="msj-form msj-error-correo" *ngIf="msjexiste">
                            <img src="assets/images/error-icon.png"><br>El correo electrónico que capturó ya se encuentra registrado
                        </div>
                        <div class="msj-form msj-error" *ngIf="msjerro">
                            <img src="assets/images/error-icon.png"><br>Algo salió mal, vuelve a intentarlo más tarde 
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <button type="submit" class="btn btn-uno">Quiero afiliarme</button>
                    </div>
                </form>
               
            </div>
        </div>
    </div>
</section>

