import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LibrosService } from "../../services/libros.service";
import { PeliculasService } from "../../services/peliculas.service";
import { DestinosService } from "../../services/destinos.service";
import { VideohomeService } from "../../services/videohome.service"
import { GurusService } from "../../services/gurus.service";
import { NotasService } from '../../services/notas.service';
import { EventosService } from 'src/app/services/eventos.service';
import { Router, ActivatedRoute } from "@angular/router";
import {MtsService} from '../../services/mts.service';
import {DomSanitizer} from "@angular/platform-browser";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  libros = [];
  peliculas = [];
  destinos = [];
  gurus = [];
  notas = [];
  eventos = [];
  video: any;
  videoall: any;
  videook: any;
  lengtarray: any;
  i=0;
  id2: any;
  videoTag;
  path: string;
  edited: boolean;
  
  @ViewChild('template') template: TemplateRef<HTMLDivElement>;
  modalRef?: BsModalRef
  // edited: boolean;
  
  

  constructor(
    private librosService: LibrosService,
    private peliculasService: PeliculasService,
    private destinosService: DestinosService,
    private gurusService: GurusService,
    private videohomeservice: VideohomeService,
    private notasService: NotasService,
    private eventosService: EventosService,
    private activeroute: ActivatedRoute,
    public environment: Environment,
    private router: Router,
    private mtsService : MtsService,
    private sanitizer: DomSanitizer,
    private _urlcanonicaservice: UrlcanonicaService,
    public meta: Meta, 
    public title: Title,
    private modalService: BsModalService,
    
    ){
      this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
      
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Movimiento Trascendental Social");
      meta.updateTag({
        name: "description",
        content: "Si pudiéramos cambiarnos a nosotros mismos, las tendencias en el mundo también podrían cambiar. Tal y como un hombre es capaz de transformar su propia naturaleza, también cambia la actitud del mundo hacia él. "
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 

      
    this.randomvideo();
    
      
    this.librosService.getLibros().subscribe((respLibros: any) => {
      this.libros = respLibros; 
      respLibros.reverse() 
      respLibros.forEach((value, index) => {
        if(index <= 5){
            this.mtsService.votos(value.idTipo).subscribe((res: any)=> {
                value.rating = Math.round(res * 10) / 10;;
            })
        } 
      });
    });
    this.peliculasService.getPeliculas().subscribe((respPeliculas: any) => {
      this.peliculas = respPeliculas; 
      respPeliculas.reverse() 
      respPeliculas.forEach((value, index) => {
        if(index <= 5){
            this.mtsService.votos(value.idTipo).subscribe((res: any)=> {
                value.rating = Math.round(res * 10) / 10;;
            })
        } 
      });
    });
    this.destinosService.getDestinos().subscribe((respDestinos: any) => {
      this.destinos = respDestinos;
      respDestinos.reverse()
    });
    this.gurusService.getGurus().subscribe((respGurus: any ) => {
      this.gurus = respGurus;
      respGurus.reverse()
    });
    this.notasService.getNotas().subscribe((respNotas: any) => {
      this.notas = respNotas;
      respNotas.reverse()
    });
    this.eventosService.getEventos().subscribe((respEventos: any) =>{
      this.eventos = respEventos;
      respEventos.reverse()
    })
    
  }
  newstr: any;
  newstr2: any;
  newstr3: any;
  newstr4: any;
  newstr5: any;
  newstr6: any;

  

  

  getRandomInt(min:any, max:any) {
    this.lengtarray = Math.floor(Math.random() * (max - min)) + min;
  }


  randomvideo(){
    this.videohomeservice.getVideosHome().subscribe(
      (data: any) => {
        this.getRandomInt(0, data.length)
        this.video = data[this.lengtarray];
        this.videook = this.video.video;
        this.videoTag = this.getVideoTag(this.videook );
      },
      error => console.log('Error :: ' + error)
    )
  }

  getVideoTag(video) {
    
    return this.sanitizer.bypassSecurityTrustHtml(
        `<video class="embebed-page-video" muted loop autoplay playsinline disableRemotePlayback>
            <source src="${video}" type="video/mp4">
            Your browser does not support HTML5 video.
        </video>`
    );
  }

  getLibroUrl(libro: any) {
    this.newstr = libro.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos = function(cadena) {
      let chars = {
        "á": "a",  ":": "",  ";": "", "é": "e", "í": "i", "ó": "o", "ú": "u", "!": "", "à": "a",
        "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n", ".": "", "¡": "", "Á": "A", "É": "E", "Í": "I",
        "Ó": "O", "Ú": "U", ",": "", "¿": "", "?": "", "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"}
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(["/libro/" + libro.id + "/" + this.newstr]);
  }

  getPeliculaUrl(pelicula: any) {
    this.newstr2 = pelicula.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos2 = function(cadena2) {
      let chars = {
        "á": "a",  ":": "",  ";": "", "é": "e", "í": "i", "ó": "o", "ú": "u", "!": "", "à": "a",
        "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n", ".": "", "¡": "", "Á": "A", "É": "E", "Í": "I",
        "Ó": "O", "Ú": "U", ",": "", "¿": "", "?": "", "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"}
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena2.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr2 = reemplazarAcentos2(this.newstr2);
    this.router.navigate(["/pelicula/" + pelicula.id + "/" + this.newstr2]);
  }
  getDestinoUrl(destino: any) {
    this.newstr3 = destino.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos3 = function(cadena3) {
      let chars = {
        á: "a",
        ":": "",
        ";": "",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        "!": "",
        à: "a",
        è: "e",
        ì: "i",
        ò: "o",
        ù: "u",
        ñ: "n",
        ".": "",
        "¡": "",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
        ",": "",
        "¿": "",
        "?": "",
        À: "A",
        È: "E",
        Ì: "I",
        Ò: "O",
        Ù: "U",
        Ñ: "N"
      };
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena3.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr3 = reemplazarAcentos3(this.newstr3);
    this.router.navigate(["/destino/" + destino.id + "/" + this.newstr3]);
  }
  getGuruUrl(guru: any) {
    this.newstr4 = guru.nombre.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos4 = function(cadena4) {
      let chars = {
        á: "a",
        ":": "",
        ";": "",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        "!": "",
        à: "a",
        è: "e",
        ì: "i",
        ò: "o",
        ù: "u",
        ñ: "n",
        ".": "",
        "¡": "",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
        ",": "",
        "¿": "",
        "?": "",
        À: "A",
        È: "E",
        Ì: "I",
        Ò: "O",
        Ù: "U",
        Ñ: "N"
      };
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena4.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr4 = reemplazarAcentos4(this.newstr4);
    this.router.navigate(["/guru/" + guru.id + "/" + this.newstr4]);
  }

  getNotaUrl(nota: any) {
    this.newstr5 = nota.titulo.replace(/\s+/g, '-').toLowerCase();
    let reemplazarAcentos=function(cadena)
    {
      let chars={
        "á":"a", ":":"",  ";":"", "é":"e", "í":"i", "ó":"o", "ú":"u", "!":"",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n", ".":"", "¡":"",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U", ",":"", "¿":"", "?":"",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
      let expr=/[áàéèíìóòúùñ:;,¿?¡!]/ig;
      let res=cadena.replace(expr,function(e)
        {return chars[e]
      });
      return res;
    }
    this.newstr5 = reemplazarAcentos(this.newstr5);
    this.router.navigate(['/nota/' + nota.id + '/' + this.newstr5]);
  }

  getEventoUrl(evento: any) {
    this.newstr6 = evento.titulo.replace(/\s+/g, '-').toLowerCase();
    let reemplazarAcentos=function(cadena)
    {
      let chars={
        "á":"a", ":":"",  ";":"", "é":"e", "í":"i", "ó":"o", "ú":"u", "!":"",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n", ".":"", "¡":"",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U", ",":"", "¿":"", "?":"",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
      let expr=/[áàéèíìóòúùñ:;,¿?¡!]/ig;
      let res=cadena.replace(expr,function(e)
        {return chars[e]
      });
      return res;
    }
    this.newstr6 = reemplazarAcentos(this.newstr6);
    this.router.navigate(['/evento/' + evento.id + '/'+ this.newstr6]);
  }

  ngOnInit() {
    this.edited = true;
    //this.openModal();
  }
  
  close() {
    this.edited = false;
  }

  
  openModal() {
    this.modalRef = this.modalService.show(this.template, {
      animated: true
    });
  }
}

 //    openModal(template: TemplateRef<any>) {
   // this.modalRef = this.modalService.show(template);
  //}

  