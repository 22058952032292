import { Component, OnInit } from '@angular/core';
import { DestinosService } from '../../services/destinos.service';
import{ ActivatedRoute } from '@angular/router'; 
import { Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';


@Component({
  selector: 'app-destino',
  templateUrl: './destino.component.html',
  styleUrls: ['./destino.component.css']
})
export class DestinoComponent implements OnInit {
  destino: any
  destinos: any[]
  idnotshow: any
  newstr: any
  path: string;

  constructor(
    private router: Router,
    private destinosService: DestinosService,
    private activatedRoute: ActivatedRoute,
    private _urlcanonicaservice: UrlcanonicaService,
    public environment: Environment,
    public meta: Meta, 
    public title: Title
    ) {
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Destinos mágicos │ MTS");
      meta.updateTag({
        name: "description",
        content: "¿Te imaginas visitar lugares mágicos del mundo que tal vez ni sabías que existían? En este planeta existen lugares mágicos por doquier."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
      this.path = this.activatedRoute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
    }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.destinosService.getDestinos().subscribe((resp: any) =>{
        this.idnotshow = param.id
        this.destino = resp.find((destino: any) => destino.id === parseInt(param.id))
      } )
      this.destinosService.getDestinos().subscribe((respdos: any) => {
      
        respdos.reverse()
        var removeIndex = respdos.map(function(item) { return item.id; }).indexOf(parseInt(this.idnotshow));
        respdos.splice(removeIndex, 1);
        this.destinos = respdos
      })
    })
  }

  getDestinoUrl(destino: any) {
    this.newstr = destino.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos = function(cadena) {
      let chars = {
        á: "a",
        ":": "",
        ";": "",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        "!": "",
        à: "a",
        è: "e",
        ì: "i",
        ò: "o",
        ù: "u",
        ñ: "n",
        ".": "",
        "¡": "",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
        ",": "",
        "¿": "",
        "?": "",
        À: "A",
        È: "E",
        Ì: "I",
        Ò: "O",
        Ù: "U",
        Ñ: "N"
      };
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(['/destino/' + destino.id + '/' + this.newstr]);
    this.getDestino(destino.id)
  }
  getDestino(id: number){
   
    this.activatedRoute.params.subscribe(param => {
      this.destinosService.getDestinos().subscribe((resp: any) => {
        this.idnotshow = param.id
        this.destino = resp.find((destino: any) => destino.id === parseInt(param.id))
        
      })
      this.destinosService.getDestinos().subscribe((respdos: any) => {
      })
    })
  }

}
