import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class GurusService {
  constructor( private http: HttpClient ) { }

  getGurus() {
    return this.http.get("assets/data/gurus.json");
  }
}
