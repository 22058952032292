import { Component, OnInit } from '@angular/core';
import { FrasesService } from '../../services/frases.service';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { ActivatedRoute } from '@angular/router';
import { Environment } from 'src/app/Globals';


@Component({
  selector: 'app-frases',
  templateUrl: './frases.component.html',
  styleUrls: ['./frases.component.css']
})
export class FrasesComponent implements OnInit {
  frases = [];
  path: string;


  constructor( 
    private frasesService: FrasesService,
    public environment: Environment,
    private _urlcanonicaservice: UrlcanonicaService,
    public meta: Meta, 
    private activeroute: ActivatedRoute,
    public title: Title
  ) {
    this.frasesService.getFrases().subscribe((resp: any) => {
      this.frases = resp;
      resp.reverse()
    } )
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Frases trascendentales │ MTS");
      meta.updateTag({
        name: "description",
        content: "Todas necesitamos frases motivacionales de vez en cuando. Sobre todo en esos días en que nos parece todo un poco más duro."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
        this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
  }

  ngOnInit(): void {
  }

}
