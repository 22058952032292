<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Notas</h1>
                <p class="titulo-descripcion">
                    Estudios demuestran que meditar aumenta las emociones positivas y el nivel de satisfacción con
                    nuestra vida, a la vez que disminuye el estrés, la ansiedad y la depresión.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let nota of notas">
            <a (click)="getNotaUrl(nota)" class="nota-card">
                <div class="nota-img" style="background-image: url(assets/images/notas/{{nota?.thumbnail}});"></div>
                <div class="nota-texto">
                    <p class="nota-titulo"> {{ nota?.titulo }} </p>
                    <hr width="50">
                    <p class="nota-descripcion"> {{ nota?.descripcion }} </p>
                    <p class="nota-btn">Seguir leyendo</p>
                </div>
            </a>
        </div>
    </div>
</div>
