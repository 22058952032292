<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                <h1 class="titulo-pagina">Aviso legal</h1>               
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row">
        
        <div class="col-12 pt-5 pb-5">
            <p><strong>Presentación </strong></p>
            <p>Bienvenido al sitio denominado “<strong>Movimiento Trascendental Social</strong>” (en lo sucesivo denominado como <strong>“MTS”</strong>) bajo el dominio http://mts.org.mx (en adelante, el “Sitio”) administrado por el Sr. <strong>CARLOS ALBERTO ARISTIZÁBAL NUÑEZ</strong>, con domicilio en <strong>Av. de los Poetas # 89 Torre B Departamento 1006, Colonia San Mateo Tlaltenango, Cuajimalpa, C.P. 05600, Ciudad de México.</strong> El presente aviso regula las condiciones de uso del Sitio y la información facilitada en el mismo. </p>
            <p>Mediante el uso, visita y/o su registro como usuario en el Sitio, Usted acepta los términos y condiciones aquí señalados y expresamente manifiesta su aceptación de conformidad con lo dispuesto en la fracción I del artículo 1803 del Código Civil Federal; por lo que, si Usted está en desacuerdo con lo mencionado, le pedimos atentamente no consulte la página.</p>
            <p><strong>“MTS”</strong> podrá en cualquier momento modificar los términos y condiciones del presente Aviso Legal, por lo que deberá realizar consultas periódicas para conocer las disposiciones vigentes de uso.</p>
            <p><strong>Objeto de la información proporcionada por el “Sitio”.</strong></p>
            <p>El Sitio tiene por objeto fomentar una vida sana, ofreciendo un espacio para compartir opiniones, experiencias y conocimiento sobre salud y bienestar general.</p>
            <p>El Sitio puede contener información de expertos o profesionales en la materia, sin que esto signifique la postura de <strong>“MTS”</strong> ante una situación o hecho específico; toda vez que tal información únicamente refleja el punto de vista particular de una persona, en este caso el experto y por lo tanto no suple las consultas con los profesionales en la medicina, ni puede considerarse como el seguimiento a algún tratamiento.</p>
            <p><strong>Acceso a contenidos.</strong></p>
            <p>El usuario puede consultar la información presente en esta página sin necesidad de registrarte en el Sitio. Sin embargo, el acceso a ciertos contenidos implican un registro con el cual creará su propio nombre de usuario y contraseña, los cuales le identificarán y habilitarán personalmente para tener acceso a tales contenidos.</p>
            <p><strong>Propiedad de la información contenida en el Sitio.</strong></p>
            <p>La información que aparece en el sitio Web, así como cualquier tipo de documentación, software, datos, servicios, marcas registradas, videos, audio, fotografías y demás objetos contenidos en el Sitio son propiedad de <strong>“MTS”</strong> y están protegidos por la legislación en materia de propiedad industrial, derechos de autor y penal.</p>
            <p>Asimismo, el usuario acepta no reproducir, duplicar, copiar, vender, revender, descompilar, desarmar ni explotar para fines comerciales ninguna parte de este sitio, su uso y acceso, ni recopilar información de visitantes o de usuarios registrados en este sitio. </p>
            <p><strong>Propiedad intelectual. </strong></p>
            <p>Las marcas, avisos comerciales, nombres comerciales, nombre de dominio y/o diseños publicados en el Sitio, se encuentran protegidos por las leyes en materia de propiedad intelectual.</p>
            <p>El contenido del Sitio: diseños, texto, gráficas, logos, imágenes, software y/o cualquier otro contenido que pueda considerarse como un derecho de propiedad industrial y/o intelectual, son propiedad de <strong>“MTS”</strong> o han sido debidamente licenciados por sus propietarios o licenciatarios a favor de éste y se encuentran protegidos de conformidad con la legislación vigente en la República Mexicana, así como por Instrumentos Internacionales. </p>
            <p>Queda estrictamente prohibido copiar, publicar, transmitir, retransmitir, transmitir por internet u otros medios análogos, modificar, eliminar, aumentar, distribuir, descargar, almacenar, reproducir, otorgar en licencia, adaptar, crear trabajos derivados de este contenido o publicar este contenido o partes de este contenido sin el consentimiento previo por escrito de <strong>“MTS”</strong>. </p>
            <p><strong>Enlaces externos.</strong></p>
            <p>El Sitio proporciona referencias, ligas o enlaces con otros sitios fuera del dominio propiedad de <strong>“MTS”</strong>, sin que esta última tenga control alguno sobre los mismos o sus recursos, ni posee o detenta los derechos de autor sobre su contenido. En este sentido, el usuario que acceda al Sitio acepta, sin ninguna reserva, que <strong>“MTS” </strong>no es responsable por la disponibilidad de otros sitios o recursos externos ni por cualquier contenido, publicidad, productos ni cualquier otro material disponible en otros dominios, ni asumirá responsabilidad alguna, directa o indirectamente, por cualquier daño o perjuicio producido o alegado en relación con el uso o confianza depositada en otro sitio con el que facilite su enlace.</p>
            <p>Asimismo, el usuario no podrá en nombre de <strong>“MTS” </strong>realizar conexiones o vínculos del Sitio con otras Páginas Web.</p>
            <p><strong>Restricciones para el usuario.</strong></p>
            <p>En particular, y a título meramente enunciativo y no limitativo, el usuario respecto del presente sitio se compromete a no reproducir total o parcialmente, a modificar, arrendar, prestar, transmitir, difundir, adaptar o poner a disposición de terceros informaciones, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material que:</p>
            <ol style="font-size: 14px;">
            <li>De cualquier forma, que sea contraria, menosprecie o atente contra los derechos fundamentales y las libertades públicas reconocidas constitucionalmente, en los Tratados Internacionales y en el resto de la legislación que rige en los Estados Unidos Mexicanos;</li>
            <li>Induzca, incite o promueva actuaciones delictivas, denigratorias, difamatorias, infamantes, violentas o, en general, contrarias a la ley, a la moral y buenas costumbres generalmente aceptadas o al orden público;</li>
            <li>Induzca, incite o promueva actuaciones, actitudes o pensamientos discriminatorios por razón de sexo, raza, religión, creencias, edad o condición;</li>
            <li>Induzca o pueda inducir a un estado inaceptable de ansiedad o temor.</li>
            </ol>
            <p>En terminos generales, el usuario se compromete a utilizar el Sitio de conformidad con las leyes aplicables, el presente Aviso, la moral y las buenas costumbres; asimismo, evitará  incurrir en cualquier hecho ilícito o transgredir leyes prohibitivas o de orden público, federales, estatales o municipales de los Estados Unidos Mexicanos.</p>
            <p>El usuario también se abstendrá de enviar cadenas de correos o cualquier otro tipo de información "spam".</p>
            <p>El usuario responderá de los daños y/o perjuicios de cualquier naturaleza que el “Sitio” pudiera sufrir como consecuencia del incumplimiento de las leyes y/o de cualquiera de las condiciones generales recogidas en el presente Aviso Legal.</p>
            <p><strong>Opiniones, comentarios y comunicaciones de los Usuarios. </strong></p>
            <p>Todo usuario que envíe o intercambie de algún modo información, contenido o materiales, incluyendo, pero sin limitarse a información comercial, ideas, conceptos o invenciones, concede a <strong>“MTS”</strong> el derecho irrevocable, intransferible y sin costo alguno, de usar los comentarios reseñas o sugerencias en la manera en que ésta estime conveniente, incluyendo sin limitación, a copiar, modificar, eliminar en su totalidad, adaptar, publicar, traducir, crear trabajos derivados de y/o vender y/o distribuir dichos comentarios y/o incorporar dichos comentarios en cualquier forma, medio o tecnología en todo el mundo.</p>
            <p>El usuario es y permanecerá siendo el único responsable por el contenido de cualquier comentario que haga y acepta expresamente indemnizar a <strong>“MTS”</strong> por todas las reclamaciones derivadas de cualquier comentario que presente. <strong>“MTS”</strong> se reserva el derecho de eliminar cualquier comentario publicado en el Sitio.</p>
            <p><strong>Exclusión de Responsabilidad </strong></p>
            <p><strong>“MTS”</strong> en ningún caso será responsable, en forma directa, indirecta, accidental, consecuencial, colateral, derivada, subsidiaria, solidaria o subsidiaria, por los servicios prestados u ofertados por otras personas o entidades, o por contenidos, informaciones, comunicaciones, opiniones o manifestaciones de cualquier tipo originados o vertidos por terceros y que resulten accesibles a través del Sitio; ni mucho menos por el uso de los contenidos vertidos en los comentarios incluidos en el sitio web sin la vigilancia o bajo el cuidado directo de un profesional de la salud. Por lo que la opinión expresada por el profesional que brinde asesoría dentro del Sitio es responsabilidad de quien la emite y no refleja la postura u opinión de <strong>“MTS”</strong> sobre el tema.</p>
            <p><strong>“MTS”</strong> no garantiza la fiabilidad, disponibilidad o continuidad del funcionamiento del Sitio, por lo que excluye cualquier responsabilidad por los daños y/o perjuicios de toda naturaleza que puedan deberse a la de los mismos.</p>
            <p>Los autores del Sitio tampoco se hacen responsables de los daños o perjuicios de cualquier tipo, incluso los informáticos o de cualquier otro tipo que pudiera ocasionar al usuario visitante el acceso a los contenidos de este sitio o por la descarga y lectura de material contenido en el sitio. </p>
            <p>Por ello, el Sitio no controla ni garantiza la ausencia de virus u otros elementos lesivos que pudieran causar daños o alteraciones en el sistema informático, en los documentos electrónicos o en los archivos del usuario de este sitio web. En consecuencia, el Sitio no responde por los daños y perjuicios que tales elementos pudieran ocasionar al usuario o a terceros, pues el contenido es meramente informativo y puede contener errores, exactitudes u omisiones.</p>
            <p>El Sitio no asume ninguna responsabilidad por los daños y perjuicios resultantes o que tengan conexión con el empleo inadecuado de la información suministrada en su sitio y página web.</p>
            <p><strong>Política de privacidad </strong></p>
            <p>De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo “LFPDPPP”) y sus disposiciones reglamentarias; el usuario manifiesta que cualesquiera datos personales proporcionados con motivo del acceso al Sitio, serán protegidos y tratados conforme a lo dispuesto por la LFPDPPP y el Aviso de Privacidad correspondiente.</p>
            <p><strong>Vigencia </strong></p>
            <p>La prestación del servicio de este sitio, en principio tiene una duración indefinida.</p>
            <p><strong>“MTS”</strong> está autorizado para modificar, dar por terminada o suspender la prestación del servicio del Sitio, así como la información contenida dentro del Sitio en cualquier momento.</p>
            <p><strong>Legislación Aplicable y Jurisdicción </strong></p>
            <p><strong>“MTS”</strong> y el usuario que acceda a este sitio, se someten expresamente a las leyes aplicables de los Estados Unidos Mexicanos y a la jurisdicción de los juzgados y tribunales competentes con residencia en el Ciudad de México, por lo que renuncian expresamente al fuero que en razón de sus domicilios presentes o futuros pudieran corresponderles.</p>
            <p>© Derechos reservados</p>
            <p>La aceptación a los términos aquí previstos se entenderá y considerará expresa, con la simple navegación en el sitio.</p>
        </div>
    </div>
</div>
