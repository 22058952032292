import { Component, OnInit } from '@angular/core';
import { NotasService } from '../../services/notas.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.css']
})
export class NotasComponent implements OnInit {
  notas: []
  newstr: any
  path: string;

  constructor(
    private notasService: NotasService,
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    public environment: Environment,
    public meta: Meta, 
    private activeroute: ActivatedRoute,
    public title: Title
  ) {
    this.path = this.activeroute.snapshot['_routerState'].url;
    this.environment.pathActual=this.path;
    this.notasService.getNotas().subscribe((resp: any) => {
      this.notas = resp;
      resp.reverse()
    } ); 
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/notas'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/notas');
      }
  
      title.setTitle("Notas │ MTS");
      meta.updateTag({
        name: "description",
        content: ""
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 

  }

  ngOnInit() {
  }

  getNotaUrl(nota: any) {
    this.newstr = nota.titulo.replace(/\s+/g, '-').toLowerCase();
    let reemplazarAcentos=function(cadena)
    {
      let chars={
        "á":"a", ":":"",  ";":"", "é":"e", "í":"i", "ó":"o", "ú":"u", "!":"",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n", ".":"", "¡":"",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U", ",":"", "¿":"", "?":"",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
      let expr=/[áàéèíìóòúùñ:;,¿?¡!]/ig;
      let res=cadena.replace(expr,function(e)
        {return chars[e]
      });
      return res;
    }
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(['/nota/' + nota.id + '/' + this.newstr]);
  }
  
}
