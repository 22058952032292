import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Environment } from 'src/app/Globals';

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.css']
})
export class AvisoLegalComponent implements OnInit {
  path: string;

  constructor(private activeroute: ActivatedRoute,public environment: Environment,) {    this.path = this.activeroute.snapshot['_routerState'].url;
  this.environment.pathActual=this.path;}

  ngOnInit(): void {
  }

}
