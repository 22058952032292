import { Component, OnInit } from '@angular/core';
import { PeliculasService } from '../../services/peliculas.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MtsService} from '../../services/mts.service';
import { NgForm } from '@angular/forms'
import { RatingModule } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from '../../Globals';

@Component({
  selector: 'app-pelicula',
  templateUrl: './pelicula.component.html',
  styleUrls: ['./pelicula.component.css']
})
export class PeliculaComponent implements OnInit {
  pelicula: any
  peliculas: any[]
  idnotshow: any
  newstr: any
  max = 5;
  rate = 0;
  isReadonly = false;
  videoURL: string;
  safeURL: any;
  dataStorgae: any;
  ComentarioLib: any
  ComentariosLib: any[]
  totalcomentarios: any
  Nombre: string
  Comentario: number
  scrollint: any
  path: string;
  videoPrincipal: any;
  sendUrl: any;

  constructor(
    private router: Router,
    private peliculasService: PeliculasService,
    private activatedRoute: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private mtsService : MtsService,
    private _urlcanonicaservice: UrlcanonicaService,
    public environment: Environment,
    public meta: Meta, 
    public title: Title
  ) {
    this.path = this.activatedRoute.snapshot['_routerState'].url;
    this.environment.pathActual=this.path;
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/pelicula/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/pelicula/');
      }
  
      title.setTitle("Películas para reflexionar │ MTS");
      meta.updateTag({
        name: "description",
        content: "Hay películas que duran hora y media, y otras que duran toda una vida."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
   }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {

      this.peliculasService.getPeliculas().subscribe((resp: any) =>{
        this.idnotshow = param.id
        this.pelicula = resp.find((pelicula: any) => pelicula.id === parseInt(param.id))
        this.scrollint = 0;
        this.mtsService.votos(this.pelicula.idTipo).subscribe((res: any)=> {
          this.pelicula.rating = Math.round(res * 10) / 10;;
          this.dataStorgae = JSON.parse(localStorage.getItem(this.pelicula.idTipo));
          // console.log(this.pelicula.youtubeLink)
          this.sendUrl = this.pelicula.youtubeLink;
          this.getComentarios(this.pelicula.idTipo);
          if(this.dataStorgae == true){
              this.isReadonly = true;
          }else{
              this.isReadonly = false;
          }
         
        })
      })

      this.peliculasService.getPeliculas().subscribe((respdos: any) => {
      
        respdos.reverse()
        var removeIndex = respdos.map(function(item: { id: any; }) { return item.id; }).indexOf(parseInt(this.idnotshow));
        respdos.splice(removeIndex, 1);
        respdos.forEach((value: { idTipo: any; rating: number; }, index: number) => {
          if(index <= 5){
              this.mtsService.votos(value.idTipo).subscribe((res: any)=> {
                  value.rating = Math.round(res * 10) / 10;;
              })
          } 
      });
        this.peliculas = respdos.slice(0, 6);
      })
    })
  }

  confirmSelection() {
    if(this.dataStorgae == true){
        console.log("Nada ya boto anteriormente que haremos ? ")
    }else{
        const params = {
            "idTipo" : this.pelicula.idTipo,
            "Tipo" : this.pelicula.Tipo,
            "votos" : this.rate
        }
        this.mtsService.califica(params).subscribe(
            resp => {
                localStorage.setItem(this.pelicula.idTipo, "true")
                this.toastr.success('Voto registrado correctamente');
                setTimeout(function () {
                  this.ngOnInit()
                }, 2000);
            },
            err => {
            console.log(err)
            }
        );
    }
  }

  sendComentario(f: NgForm){
      f.value.Megusta = 0;
      f.value.NoMegusta = 0;
      f.value.Idtipo = this.pelicula.idTipo;
      f.value.Tipo = this.pelicula.Tipo;

      this.mtsService.postComentario(f.value).subscribe(
          resp => {
            f.reset();
            this.toastr.success('Comentario agregado correctamente');
            this.getComentarios(this.pelicula.idTipo);
          },
          err => {
          console.log(err)
          }
      );
  }

  like(comentario: any){
      this.mtsService.postComentarioVoto(comentario.id, true).subscribe(
          resp => {
              console.log(resp)
              this.getComentarios(this.pelicula.idTipo);
              //aqui ya realizo voto al comentario ¿ Que haremos ?
          },
          err => {
          console.log(err)
          }
      );
  }

  dislike(comentario: any){
      this.mtsService.postComentarioVoto(comentario.id, false).subscribe(
          resp => {
              this.getComentarios(this.pelicula.idTipo);
              //aqui ya realizo voto al comentario ¿ Que haremos ?
          },
          err => {
          console.log(err)
          }
      );
  }

  getComentarios(id){
      this.mtsService.getComentarios(id).subscribe((res: any)=> {
          this.ComentariosLib = res;
          this.totalcomentarios = this.ComentariosLib.length;
      })
  }

  // getVideoIframe(url: string) {
    
  //   if(this.scrollint === 1){
      
  //   }else{
  //     var video, results;
 
  //     if (url === null) {
  //         return '';
  //     }
  //     results = url.match('[\\?&]v=([^&#]*)');
  //     video   = (results === null) ? url : results[1];
  //     this.scrollint = 1;
  //     return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video + '?loop=1&amp;playlist=' + video);   
      
  //   }  
  // }
  getVideoIframe(url?:undefined) {
    if(url != undefined){
      this.sendUrl = url;
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.sendUrl + '?loop=1&amp;playlist=' + this.sendUrl);  
  }

  ngAfterViewChecked(): void{
    if(this.videoPrincipal != undefined){
      this.getVideoIframe(this.videoPrincipal.idVideo);
    }
  }

  getPeliculaUrl(pelicula: any) {
    this.newstr = pelicula.titulo.replace(/\s+/g, '-').toLowerCase();
    let reemplazarAcentos=function(cadena)
    {
      let chars={
        "á":"a", ":":"",  ";":"", "é":"e", "í":"i", "ó":"o", "ú":"u", "!":"",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n", ".":"", "¡":"",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U", ",":"", "¿":"", "?":"",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
      let expr=/[áàéèíìóòúùñ:;,¿?¡!]/ig;
      let res=cadena.replace(expr,function(e){return chars[e]});
      return res;
    }
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(['/pelicula/' + pelicula.id + '/' + this.newstr]);
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView(
    {behavior: "smooth", block: "start", inline: "nearest"}
    );
  }
}