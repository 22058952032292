import { Component, OnInit } from '@angular/core';
import { DestinosService } from '../../services/destinos.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';

@Component({
  selector: 'app-destinos-magicos',
  templateUrl: './destinos-magicos.component.html',
  styleUrls: ['./destinos-magicos.component.css']
})
export class DestinosMagicosComponent implements OnInit {
  destinos = [];
  path: string;



  constructor(
    private destinosService: DestinosService,
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    public environment: Environment,
    private activeroute: ActivatedRoute,
    public meta: Meta, 
    public title: Title
  ) {
    this.destinosService.getDestinos().subscribe((resp: any) => {
      this.destinos = resp;
      resp.reverse()
    })
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Destinos mágicos │ MTS");
      meta.updateTag({
        name: "description",
        content: "¿Te imaginas visitar lugares mágicos del mundo que tal vez ni sabías que existían? En este planeta existen lugares mágicos por doquier. "
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
      this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
      
  }
   newstr: any;

  ngOnInit() {
  }
  getDestinoUrl(destino: any) {
    this.newstr = destino.titulo.replace(/\s+/g, "-").toLowerCase();
    let reemplazarAcentos = function(cadena) {
      let chars = {
        á: "a",
        ":": "",
        ";": "",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        "!": "",
        à: "a",
        è: "e",
        ì: "i",
        ò: "o",
        ù: "u",
        ñ: "n",
        ".": "",
        "¡": "",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
        ",": "",
        "¿": "",
        "?": "",
        À: "A",
        È: "E",
        Ì: "I",
        Ò: "O",
        Ù: "U",
        Ñ: "N"
      };
      let expr = /[áàéèíìóòúùñ:;,¿?¡!]/gi;
      let res = cadena.replace(expr, function(e) {
        return chars[e];
      });
      return res;
    };
    this.newstr = reemplazarAcentos(this.newstr);
    //nombre = nombre.replace(/\s+/g, '-');
    //this.router.navigate(['/columnista', nombre, this.newstr, idx]);
    this.router.navigate(["/destino/" + destino.id + "/" + this.newstr]);
  }

}
