<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                <h1 class="titulo-pagina">{{ nota?.titulo }}</h1>
               
            </div>
        </div>
    </div>
</section>
<div class="container pb-5 pt-5">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-12  mb-3">
                    <div class="imagen-nota" style="background-image: url(assets/images/notas/{{nota?.thumbnail}});"></div>
                    <p class="autor">Por: {{ nota?.autor }} </p>
                    <p class="fecha"> {{ nota?.fecha }} </p>
                    
                    <div [innerHTML]="nota?.contenido" class="mt-3 nota-contenido" > </div>
                    <div  [innerHTML]="nota?.referencia" class="mt-3" > </div>
                </div>
                
            </div>
        </div>
        <!--     sideBar       -->
        <div class="col-lg-3 otras-notas">
            <strong>También te puede interesar:</strong>
            <ul class="lista">
                <li *ngFor="let nota of notas.slice(0, 6);">
                    <a (click)="getNotaUrl(nota)" style="cursor:pointer">
                        {{ nota?.titulo }}
                    </a>
                </li>
            </ul>
        </div>
        <!--     sideBar       -->
    </div>
</div>
