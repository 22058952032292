<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Películas para reflexionar</h1>
                <p class="titulo-descripcion">
                    Hay películas que duran hora y media, y otras que duran <strong>toda una vida.</strong>
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="row">
        <!-- PELICULA  -->
        <div class="col-lg-6 ">
            <div class="row mb-5">
                <div class="col-sm-5">
                    <img class="pelicula-foto" src="assets/images/peliculas/{{ pelicula?.portada }}">
                </div>
                <div class="col-sm-7">
                    <p class="pelicula-fecha text-gold mb-1">{{ pelicula?.fecha }}</p>
                    <h1 class="pelicula-nombre">{{ pelicula?.titulo }}</h1>
                    <p class="pelicula-nombre-original">{{ pelicula?.tituloOriginal }}</p>
                    <p class="pelicula-genero text-gold">{{ pelicula?.genero }}</p>
                    <div class="calificacion mb-4">
                        <p><strong>{{ pelicula?.rating }}</strong>/5.0</p>
                        <img class="rating" src="https://mts.org.mx/assets/images/rating/{{ pelicula?.rating }}.svg">
                    </div>
                    <button class="btn-uno mb-5" (click)="scroll(comentar)">Dinos tu opinión</button>
                </div>
            </div>
        </div>
        <div class="col-lg-6">

            <p class="titulo titulo-pelicula">Sinopsis</p>
            <div  [innerHTML]="pelicula?.resena"> </div>
            <p class="titulo titulo-pelicula">Dirigida por:</p>
            <p class="text-gold"> {{ pelicula?.director }}</p>
            <p class="titulo titulo-pelicula">Reparto:</p>
            <p class="text-gold">{{ pelicula?.reparto }}</p>
        </div>
    </div>
</div>
<section style="background-color: #262D3F" class="pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <iframe class="trailer" [src]="getVideoIframe()" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <!-- <iframe [src]="getVideoIframe()" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  -->
            </div>
        </div>
    </div>

</section>
<section style="background-color: #EDEEEE;" class="pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-3">
                <p class="titulo">
                    También puede interesarte
                </p>
            </div>
             <!--  PELICULAS SUGERIDOS  -->
             <div  *ngFor="let pelicula of peliculas" class=" col-lg-2 col-md-3 col-6">
                <a class="pelicula-sugerido" (click)="getPeliculaUrl(pelicula)" style="cursor:pointer">
                    <img class="pelicula1" src="https://mts.org.mx/assets/images/peliculas/{{ pelicula?.thumbnail }}">
                    <p class="nombre-pelicula1">{{ pelicula.titulo }}</p>
                    <img class="rating" src="https://mts.org.mx/assets/images/rating/{{ pelicula?.rating }}.svg">
                </a>
            </div>
            <!-- FIN PELICULAS SUGERIDOS  -->
            
        </div>
    </div>
</section>
<div class="container pt-5 pb-5" #comentar>
    <div class="row">
        <div class="col-lg-6 col-md-8"  >
            <h3 class="titulo">Danos tu calificación y/o comenta que te parecio esta película.</h3>
             <!--  RATING  -->
             <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly" (click)="confirmSelection()"></rating>
         <div class="rating-estrellas">
             <pre  style="margin:15px 0;">Tu calificación: <b>{{rate}}</b>; </pre>
         </div>
         <!--al presional la tecla ENTER se envia la calificacion   -->
         
        <!-- FIN RATING  -->
        <p class="mt-4 "><strong>Comentarios</strong></p>
        <hr width="100%">

        <form ngNativeValidate #form="ngForm" (ngSubmit)="sendComentario(form)" class="row">
            <div class="form-group col-12">
                <label for="Nombre">Nombre</label>
                <input [(ngModel)]="Nombre" name="Nombre" class="form-control" id="Nombre" type="text"  required>
            </div>
            <div class="form-group col-12">
                <label for="Comentario">Dejanos tus comentarios</label>
                <textarea [(ngModel)]="Comentario" rows="5" id="Comentario" name="Comentario" class="form-control" required></textarea>
            </div>
            <div class="text-center col-12">
                <button type="submit" class="btn btn-uno">
                    Enviar
                </button>
            </div>
        </form>

        <div class="comentarios">
            <div class="be-comment-block">
                <h1 class="comments-title">Comentarios ({{totalcomentarios}})</h1>
                <div  *ngFor="let ComentarioLib of ComentariosLib" class="be-comment">
                    <div class="be-img-comment">
                        <a href="blog-detail-2.html">
                            <img src="https://mts.org.mx/assets/images/avatar.jpg" alt="" class="be-ava-comment">
                        </a>
                    </div>
                    <div class="be-comment-content">

                        <span class="be-comment-name" [innerHTML]="ComentarioLib.nombre" >
                        
                        </span>
                        <span class="be-comment-time">
                            <i class="fa fa-clock-o"></i>
                            {{ ComentarioLib.fecha | date:'mediumDate' }}      
                        </span>

                        <div class="be-comment-text" > 
                            <p [innerHTML]="ComentarioLib.comentario"></p>
                            <span (click)="like(ComentarioLib)" style="cursor: pointer;width: 40px;height: 20px;"><img  src="assets/images/like.svg" width="20">   {{ ComentarioLib.megusta }}    </span>
                            <span (click)="dislike(ComentarioLib)" style="cursor: pointer;width: 40px;height: 20px;"><img  src="assets/images/dislike.svg" width="20">  {{ ComentarioLib.noMeGusta  }}    </span>

                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>