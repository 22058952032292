import { Component, OnInit, HostListener } from '@angular/core';
import { Environment } from '../../Globals';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
   constructor() {  } 
  ngOnInit() {
      
  }
}
