<footer>
    <div class="container">
        <div class="row ">
            <div class="col-lg-2 col-md-12 text-center logo-footer">
                <img src="https://mts.org.mx/assets/images/logo-mts-blanco.svg">
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="navbar-footer">
                    <ul>
                        <li>
                            <a routerLink="/">Inicio</a>
                        </li>
                        <li>
                            <a routerLink="/libros">Libros</a>
                        </li>
                        <li>
                            <a routerLink="/peliculas">Películas</a>
                        </li>
                        <li>
                            <a routerLink="/videos">Videos</a>
                        </li>
                        <li>
                            <a routerLink="/destinos-magicos">Destinos Mágicos</a>
                        </li>
                        <li>
                            <a routerLink="/gurus">Gurús</a>
                        </li>
                        <li>
                            <a routerLink="/tecnicas-de-meditacion">Técnicas de Meditación</a>
                        </li>
                        <li>
                            <a routerLink="/contacto">Contáctanos</a>
                        </li>
                        <li>
                            
                            <a href="https://www.instagram.com/movimientotrascendentalsocial/" target="_blank" >Síguenos <img src="../../../assets/images/instagram.svg" width="20"> </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-12">
                <!--
                <div class="redes-footer">
                    <a href=""> <img width="26px" src="https://mts.org.mx/assets/images/ico-footer-facebook.svg"> </a>
                    <a href=""> <img width="26px" src="https://mts.org.mx/assets/images/ico-footer-tweeter.svg"> </a>
                    <a href=""> <img width="26px" src="https://mts.org.mx/assets/images/ico-footer-pinterest.svg"> </a>
                </div>
                -->
            </div>
            <hr>
            <div class="col-sm-4 legales">
                <p>Copyright © 2023 Movimiento Trascendental Social.</p>
            </div>
            <div class="col-sm-8 legales">
                  <a routerLink="/aviso-de-privacidad">|  Aviso de privacidad</a> <a routerLink="/aviso-legal" >Aviso legal</a>
            </div>

        </div>
    </div>
</footer>