<section class="section-titulo mb-5">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                <h1 class="titulo-pagina">Gurús</h1>
                <p class="titulo-descripcion">
                    Un verdadero Gurú es como el océano, está disponible para lo que se necesite. Depende de cada uno de
                    nosotros si lo aprovechamos para mirarlo desde lejos, mojarnos los pies o sumergirnos en las
                    profundidades y encontrar grandes tesoros.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="seccion-gurus">
    <div class="container">
        <div class="row">
            <div class="col-md-8 text-center offset-md-2 mb-5">
                <img class="flor" src="https://mts.org.mx/assets/images/ico-loto.svg">
                <p class="titulo-xl">
                    ¿Por qué aún necesitamos gurús?
                </p>
                <div class="pleca-center"></div>
                <p>En el marco del hinduismo, gurú significa ‘maestro espiritual’. Desde hace muchos siglos este término
                    se ha empleado en la India. Al maestro espiritual se le consideraba una persona importante que
                    mostraba el sendero del yoga, enseñaba las técnicas de meditación y a quien se le pedían consejos.
                    Aquel que pone luz en la oscuridad. </p>
            </div>
            

            <div *ngFor="let guru of gurus" class="col-lg-3 col-md-4 col-6 gurus-contenedor" >
                <div class="guru-card card2">
                    <img class="guru-foto" src="assets/images/gurus/{{guru?.thumbnail}}">
                    <p class="guru-titulo">Gurú</p>
                    <p class="guru-nombre">{{ guru.nombre }} </p>
                    <a href="{{guru?.url}}" class="guru-link" target="_blank" >{{guru?.sitio}} </a>
                    <a (click)="getGuruUrl(guru)" class="btn btn-uno btn-guru" style="cursor:pointer"> ¡Conócelo!</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="banner mt-5">
    <div class="overlay">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xl-5  text-white ">
                    <p class="frase">“Tu peor enemigo no te puede dañar tanto como tus propios pensamientos. Ni tu
                        padre, ni tu madre, ni tu amigo más querido, te pueden ayudar tanto como tu propia mente
                        disciplinada.”<br><br>
                        Dhammapada 3:10-11</p>
                </div>
            </div>
        </div>
    </div>
</section>