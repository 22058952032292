<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Películas para reflexionar</h1>
                <p class="titulo-descripcion">
                    Hay películas que duran hora y media, y otras que duran <strong>toda una vida.</strong>
                </p>
            </div>
        </div>
    </div>
</section>


<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col-12 text-center mb-3">
            <p>¿Existe algúna película que haya cambiado tu forma de ver la vida?, ¡Compártela con nosotros!</p>
            <button class="btn btn-uno" (click)="openModal()" > compartir</button>
        </div>

        <div *ngFor="let pelicula of peliculas" class="col-lg-3 col-sm-4 col-6">
            <a class="card-pelicula" (click)="getPeliculaUrl(pelicula)" style="cursor:pointer">
                <img class="pelicula" src="assets/images/peliculas/{{ pelicula?.thumbnail }}">
                <p class="nombre-pelicula">{{ pelicula.titulo }}</p>
                <p class="nombre-autor">{{ pelicula.fecha }}</p>
                <img class="rating" src="https://mts.org.mx/assets/images/rating/{{ pelicula?.rating }}.svg">
                <p class="ver-mas">Ver más</p>
            </a>
        </div>

    </div>
</div>
<section class="banner">
    <div class="overlay">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 col-xl-6 offset-lg-1 offset-xl-3 text-white text-center">
                    <p class="frase">“Cuando <strong>fluyes </strong> con la <strong>vida</strong> el universo te trae
                        todas las <strong>respuestas</strong>”</p>
                    <small>ISHA</small>
                </div>
            </div>
        </div>
    </div>
</section>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
    <div class="modal-dialog modal-video">
        <div class="modal-content">
            <div class="modal-header ">
                <h3>¡Compártelo con nosotros!</h3>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="compartir-form">
                <form ngNativeValidate #form="ngForm" (ngSubmit)="sendRecomendacion(form)" class="row">
                    <div class="form-group col-md-6">
                        <label for="Nombre">Nombre</label>
                        <input [(ngModel)]="Nombre" name="Nombre" class="form-control" id="Nombre" type="text"  required>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="Correo">Email</label>
                        <input [(ngModel)]="Correo" name="Correo" class="form-control" id="Correo" type="email"  required>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="NombrePelicula">Nombre de la pelicula</label>
                        <input [(ngModel)]="NombrePelicula" name="NombrePelicula" class="form-control" id="NombrePelicula" type="text"  required>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="Director">Director</label>
                        <input [(ngModel)]="Director" name="Director" class="form-control" id="Director" type="text" required>
                    </div>
                    <div class="form-group col-md-12">
                        <label for="ComentarioRecomendacion">Dejanos tus comentarios</label>
                        <textarea [(ngModel)]="ComentarioRecomendacion" rows="5" id="ComentarioRecomendacion" name="ComentarioRecomendacion" class="form-control" required></textarea>
                    </div>
                    <div class="text-center col-12">
                        <button type="submit" class="btn btn-uno" style="margin: auto; display: table;">Compartir</button><br>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>