<!-- <ng-template #template  >
 
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <img src="../../../assets/images/modal-ayam.jpg" width="100%">
  
</ng-template> -->

<!--
<div class="seudomodal" *ngIf="edited" (click)="close()">
	<div class="modaltest">
		
			<button type="button" class="destroy" (click)="close()">
				<span>&times;</span>
			</button>
		
            
			<div class="modal-imagen">
                <a href="https://drsimi.com/RiegoDeAuxilioRegistro23" target="blank"> <img class="img-fluid" src="../../../assets/images/modal-reforestacion.jpg"></a>
			</div>
            
	</div>
</div>
-->
<section class="cover">
    <header>
        <div class="overlay"></div>
        <div class="video-fix" [innerHTML]="videoTag"></div>
        
        <div class="container h-100">
            <div class="d-flex h-100 align-items-center">
                <div class="header-text text-white">
                    <p class="titulo-header">Pon tu corazón, mente y alma, incluso en los actos más pequeños...</p>
                    <p class="subtitulo-header mb-4">Swami Sivananda</p>
                    <!-- <audio controls loop autoplay playsinline disableRemotePlayback>
                        <source src="assets/audio/background.mp3" type="audio/mpeg">
                        Your browser does not support HTML5 video.
                    </audio><br><br> -->
                    <!--
                    <div class="header-redes mb-4">
                        <a href=""> <img src="https://mts.org.mx/assets/images/ico-tweeter.svg"> </a>
                        <a href=""> <img src="https://mts.org.mx/assets/images/ico-facebook.svg"> </a>
                    </div>
                    -->
                    <a routerLink="/afiliate" class="btn btn-uno">¡AFÍLIATE!</a><br>
                    <a href="https://www.instagram.com/movimientotrascendentalsocial/" target="_blank" class="redes"><img src="../../../assets/images/instagram.svg"> Síguenos</a>
                </div>
            </div>
        </div>
    </header>
</section>
<div class="container my-5 ">
    <div class="row pt-5">
        <div class="col-md-5 pt-4 text-center">
            <img class="rocas" src="https://mts.org.mx/assets/images/rocas-balance.png">
        </div>
        <div class="col-md-7">
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <h1 class="titulo-xl">“Be the change you<br>
                want to see in the <br>
                world.”</h1>
            <p>Si pudiéramos cambiarnos a nosotros mismos, las tendencias en el mundo también podrían cambiar. Tal y
                como un hombre es capaz de transformar su propia naturaleza, también cambia la actitud del mundo
                hacia él. No necesitamos esperar a ver lo que hacen los demás<br><br>
                Gandhi insiste en que la transformación personal y la social han de ir de la mano; nunca dice que
                baste con el cambio del individuo. Todo lo contrario. En su lucha por un mundo mejor, Gandhi
                insistía en que sólo un grupo de personas trabajando juntas con disciplina y persistencia podrá ser
                capaz de combatir la injusticia.</p>
        </div>
    </div>
</div>
<section class="section-gurus pb-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-8 text-center offset-md-2 mb-5">
                <img class="flor" src="https://mts.org.mx/assets/images/ico-loto.svg">
                <p class="titulo-xl">
                    Gurus
                </p>
                <div class="pleca-center"></div>
                <p>Un verdadero Gurú es como el océano, está disponible para lo que se necesite. Depende de cada uno de
                    nosotros si lo aprovechamos para mirarlo desde lejos, mojarnos los pies o sumergirnos en las
                    profundidades y encontrar grandes tesoros.</p>
            </div>
            <!--   Gurus    -->
            <div *ngFor="let guru of gurus.slice(0, 2);" class="col-md-6">
                <div class="guru-card-back"></div>
                <div class="guru-card">
                    <img class="guru-foto" src="assets/images/gurus/{{guru?.thumbnail}}">
                    <div class="row">
                        <div class="col-8 offset-4">
                            <p class="subtitulo-dorado"> Gurú</p>
                            <p class="titulo-guru">{{ guru?.nombre }}</p>
                            <p class="descripcion-guru"> {{ guru?.descripcionCorta }} </p>
                            <a class="text-gold link-guru mt-3" href="{{guru?.url}}"
                                target="_blank">{{ guru?.sitio }}</a><br><br>
                            <a (click)="getGuruUrl(guru)" style="cursor:pointer" class="btn btn-uno"> ¡Conócelo!</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--    FIN  Gurus    -->
        </div>
    </div>
</section>




<section class="eventos-home" style="display:none" >
    <div class="container">
        <div class="row">
            <div class="col-12" *ngFor="let evento of eventos.slice(0,1)">
                <a (click)="getEventoUrl(evento)" class="evento-cont">
                    <div class="row">
                        <div class="col-12 text-center  mb-2">
                            <img class="flor" src="https://mts.org.mx/assets/images/ico-loto.svg">
                            <p class="titulo-xl">
                                Eventos
                            </p>
                            <div class="pleca-center"></div>
                            
                        </div>
                        
                        <div class="evento-texto col-md-6 evento-card">
                            <p class="intro-evento-home"> {{ evento?.intro }} </p>
                            <p class="titulo-evento-home"> {{ evento?.titulo }} </p>
                            <div class="pleca"></div>
                            <p class="hora-fecha-evento-home"><span class="cancel"> {{ evento?.fecha }} </span>-  {{ evento?.hora }}</p>
                            <p class="nueva-fecha"><strong>Cambio de fecha: </strong>  {{ evento?.fechaNueva }} </p>
                            <p class="lugar-evento-home"> {{ evento?.lugar }} </p>
                            <p class="costo-evento-home"> {{ evento?.costo }} </p>
                            
                            <span class="btn-uno">¡Quiero registrarme!</span>
                        </div>
                        <div class="evento-imagen col-md-6" >
                            <div class="imagen-evento" style="background-image: url(assets/images/eventos/{{evento?.thumbnail}});"></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>






<div class="container pb-5 pt-5">
    <div class="row">
        <div class="col-lg-4">
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo">
                Libros que cambiarán tu vida
            </p>
            <p>¿Alguna vez has leído un libro que te impactó tanto como para cambiar tu vida? ¿Uno que ha hecho que
                desde entonces todo sea diferente?<br><br>
                Puede sonar exagerado, pero lo cierto es que existen algunos libros que por su alto valor y utilidad
                para los lectores, ayudan de verdad a ver el mundo de forma diferente.</p>
            <a routerLink="/libros" class="btn btn-uno mb-5"> Más libros</a>
        </div>
        <!-- LIBROS SUGERIDOS -->
        <div  *ngFor="let libro of libros.slice(0, 4);" class="col-lg-2 col-sm-3 col-6">
            <a class="libro-sugerido" (click)="getLibroUrl(libro)" style="cursor:pointer">
                <img class="libro1" src="https://mts.org.mx/assets/images/libros/{{ libro?.thumbnail }}">
                <p class="nombre-libro1">{{ libro.titulo }}</p>
                <img class="rating" src="https://mts.org.mx/assets/images/rating/{{ libro?.rating }}.svg">
            </a>
        </div>
        <!--FIN LIBROS SUGERIDOS -->
    </div>
</div>


<section class="seccion-meditacion">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-8 offset-lg-6 offset-md-4">
                <div class="card-darks">
                    <img class="flor" src="https://mts.org.mx/assets/images/ico-loto.svg">
                    <p class="titulo-xl">
                        Meditación
                    </p>
                    <div class="pleca"></div>
                    <p>Su finalidad es acallar el torrente de pensamientos que fluye en nuestra cabeza.La primera de
                        ellas es la que se explica en concentración sobre contar respiraciones. La frontera entre
                        concentración y meditación es muy difusa…<br><br>
                        Cualquiera que haya practicado la meditación sabe qué difícil es hacer callar nuestra
                        ”conversación mental” para conectarnos con nuestra mente más profunda, sabia e intuitiva.</p>
                    <a routerLink="/tecnicas-de-meditacion" class="btn btn-uno"> Técnicas de meditación</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="contenedor-destinos">
        <div class="destinos-descripcion">
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo-xl">Destinos Mágicos </p>
            <div class="pleca"></div>
            <p>Algunos de ellos tienen una magia peculiar, gracias a leyendas literarias y los mitos históricos que
                giran a su alrededor. Por ello, estos destinos resultan interesantes para los turistas y curiosos en
                todo el mundo.<br><br>
                localidades que mantienen su arquitectura original, tradiciones, historia y cultura con diferentes
                niveles de desarrollo, en donde la actividad turística contribuye para elevar los niveles de bienestar,
                mantener, fortalecer y optimizar el aprovechamiento racional de los recursos y atractivos naturales.</p>
            <a routerLink="/destinos" class="btn btn-uno">Ver destinos</a>
        </div>
        <div class="destinos-grid">

            <!--   Destinos    -->
            <a *ngFor="let destino of destinos.slice(0,3)" class="destino" (click)="getDestinoUrl(destino)" style="cursor:pointer">
                <div class="destino-text">
                    <img width="20" src="https://mts.org.mx/assets/images/ico-point.svg">
                    <p class="destino-nombre"><strong>{{ destino?.titulo }},</strong> {{ destino?.lugar }}</p>
                    <small>Ver más</small>
                </div>
                <div class="destino-img"
                    style="background-image: url(https://mts.org.mx/assets/images/destinos/{{destino?.thumbnail}});">
                    <div class="destino-img-overlay"></div>
                </div>
            </a>
            <!--    FIN DESTINOS    -->
        </div>
    </div>
</div>
<section class="section-peliculas pt-4 pb-4">
    <div class="container  pb-5 pt-5">
        <div class="row">
            <!-- PELICULAS SUGERIDAS
            <div class="col-lg-4 mb-5">
                <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
                <p class="titulo">
                    Películas para reflexionar
                </p>
                <p>Las películas son capaces de despertarnos todo tipo de emociones. Nos alegran, nos hacen llorar de
                    pena o de risa, nos inspiran… Y aún así, normalmente solo las vemos para desconectar o para pasar el
                    rato.
                    Sin embargo, si sabemos aprovechar su poder, pueden convertirse en uno de nuestros mejores aliados
                    para desarrollar nuestro máximo potencial.</p>
                <a routerLink="/peliculas" class="btn btn-uno "> Más películas</a>
            </div>
             
             
            <div  *ngFor="let pelicula of peliculas.slice(0, 4);" class="col-lg-2 col-sm-3 col-6">
                <a class="libro-sugerido" (click)="getPeliculaUrl(pelicula)" style="cursor:pointer">
                    <img class="libro1" src="https://mts.org.mx/assets/images/peliculas/{{ pelicula?.thumbnail }}">
                    <p class="nombre-libro1">{{ pelicula.titulo }}</p>
                    <img class="rating" src="https://mts.org.mx/assets/images/rating/{{ pelicula?.rating }}.svg">
                </a>
            </div>
            FIN PELICULAS SUGERIDAS -->

            <div class="col-12" *ngFor="let nota of notas.slice(0,1)">
                <a (click)="getNotaUrl(nota)" class="nota-card">
                    <div class="row">
                        
                        <div class="nota-texto col-md-6">
                            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
                            <p class="titulo"> {{ nota?.titulo }} </p>
                            <p class="nota-descripcion"> {{ nota?.descripcion }} </p>
                            <span class="btn-uno">Seguir leyendo</span>
                        </div>
                        <div class="col-md-6">
                            <div class="nota-img" style="background-image: url(assets/images/notas/{{nota?.thumbnail}});"></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>


