<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                <h1 class="titulo-pagina">Eventos</h1>
            </div>
        </div>
    </div>
</section>
<div class="container pb-5 pt-5">
    
    <div class="row">
        <div class="col-lg-6">
            <p>Te invitamos al primer encuentro:</p>
            <h2>{{ evento?.titulo }}</h2>
            <img src="assets/images/eventos/{{evento?.portada}}" alt="" class="img-fluid">
            <div class="card-evento">
                <div class="fecha-evento"><img src="assets/images/icono-fecha.svg" width="24"><span class="cancel">{{evento?.fecha}}</span></div>
                <div class="hora-evento"><img src="assets/images/icono-hora.svg" width="24">{{evento?.hora}}</div>
                <p class="nueva-fecha"><strong>Cambio de fecha: </strong>  {{ evento?.fechaNueva }} </p>
                <p class="lugar"><img src="assets/images/icono-lugar.svg" width="24"> {{evento?.lugar}}</p>
                <p class="costo"><img src="assets/images/icono-precio.svg" width="24"> {{evento?.costo}}</p>
                <hr>
                <p class="frase">{{evento?.frase}}</p> 
                <div class="informacion-evento"   [innerHTML]="evento?.informacion" > </div>  
            </div>
        </div>
        <div class="col-lg-6">
            <form [formGroup]="EventoForm" (ngSubmit)="onSubmit()" class="row form-container">
                <div class="col-12">
                    <p><q>Aprender sin reflexionar es malgastar la energía</q><br><small>Kun FuTse, Confucio</small> </p>
                </div>
                <div class="form-group col-12">
                    <label for="Nombre">Nombre</label>
                    <input formControlName="Nombre" class="form-control" type="text" [ngClass]="{ 'is-invalid': submitted && f['Nombre'].errors }"/>
                    <div *ngIf="submitted && f['Nombre'].errors" class="invalid-feedback">
                      <div *ngIf="f['Nombre'].errors['required']">El nombre es necesario</div>
                      <div *ngIf="f['Nombre'].errors['minlength']">El nombre debe de ser minimo de 3 caracteres</div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="Email">Email</label>
                    <input formControlName="Email" class="form-control" type="email" [ngClass]="{ 'is-invalid': submitted && f['Email'].errors }"/>
                    <div *ngIf="submitted && f['Email'].errors" class="invalid-feedback">
                      <div *ngIf="f['Email'].errors['required']">Email es necesario</div>
                      <div *ngIf="f['Email'].errors['pattern']">Email es invalido</div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="Estado">Estado</label>
                    <select formControlName="Estado" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['Estado'].errors }">
                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">Baja California Sur</option>
                        <option value="Campeche">Campeche</option>
                        <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                        <option value="Colima">Colima</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="Ciudad de México">Ciudad de México</option>
                        <option value="Durango">Durango</option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco">Jalisco</option>
                        <option value="México">México</option>
                        <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz de Ignacio de la Llave">Veracruz de Ignacio de la Llave</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                    </select>
                    <div *ngIf="submitted && f['Estado'].errors" class="invalid-feedback">
                        <div *ngIf="f['Estado'].errors['required']">Estado es necesario</div>
                    </div>
                </div>
                <div class="form-group col-12">
                    <label for="Telefono">Teléfono</label>
                    <ngx-intl-tel-input
                        [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }"
                        [cssClass]="'custom'" 
                        [enableAutoCountrySelect]="true" 
                        [enablePlaceholder]="true" 
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" 
                        [selectedCountryISO]="CountryISO.Mexico"
                        [maxLength]="15" 
                        [phoneValidation]="true" 
                        [separateDialCode]="separateDialCode"
                        searchCountryPlaceholder='Buscar'
                        name="phone" formControlName="phone">
                    </ngx-intl-tel-input>
                  <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback">
                    <div *ngIf="f['phone'].errors['required']">Número telefónico necesario</div>
                    <div *ngIf="f['phone'].errors">Número telefónico invalido</div>
                  </div>
                </div>
                <div class="form-check col-12">
                    <input type="checkbox" formControlName="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f['acceptTerms'].errors }"/>
                    <div *ngIf="submitted && f['acceptTerms'].errors" class="invalid-feedback">
                        Acepta el aviso de privacidad
                    </div>
                    <label class="form-check-label" for="acceptTerms">Estoy de acuerdo con el <a routerLink="/aviso-de-privacidad" target="_blank" > Aviso de privacidad</a></label>
                </div>
                <div class="col-12 text-center">
                    <div class="msj-form msj-enviando" *ngIf="msjenvio">
                        <img src="assets/images/loading.gif">
                    </div>
                    <div class="msj-form msj-error-correo" *ngIf="msjexiste">
                        <img src="assets/images/error-icon.png"><br>El correo electrónico que capturó ya se encuentra registrado
                    </div>
                    <div class="msj-form msj-error" *ngIf="msjerro">
                        <img src="assets/images/error-icon.png"><br>Algo salió mal, vuelve a intentarlo más tarde 
                    </div>
                    <div class="msj-form succes" *ngIf="msjsucces">
                        <img src="assets/images/success-icon.png"><br><strong>¡Registro exitoso!</strong> 
                    </div>
                </div>
                <div class="form-group col-12">
                    <button type="submit" class="btn btn-uno">Quiero afiliarme</button>
                </div>
            </form>
        </div>


        <!--
        <div class="col-12  mb-3">
            <div class="imagen-evento" style="background-image: url(assets/images/eventos/{{evento?.thumbnail}});"></div>
            <p class="autor">Por: {{ evento?.autor }} </p>
            <p class="fecha"> {{ evento?.fecha }} </p>
            
            <div [innerHTML]="evento?.contenido" class="mt-3 evento-contenido" > </div>
            <div  [innerHTML]="evento?.referencia" class="mt-3" > </div>
        </div>
        -->
    </div>
        
</div>

