<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Videos</h1>
                <p class="titulo-descripcion">
                    A continuación puedes ver una selección de videos recomendados para pensar sobre la vida, las
                    relaciones y el modo en el que las experimentamos.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container mt-5 mb-5">
    <div class="row ">
        <div class="col-12 text-center  pb-5">
            <img class="flor" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo-xl">
                Videos para reflexionar
            </p>
            <div class="pleca-center"></div>
            <p>Te mostramos algunos de los vídeos para reflexionar que te harán pensar de una forma u otra sobre
                diferentes aspectos de tu vida y que, podrán cambiarte la idea que tenías hacia ciertos aspectos
                principales de tu felicidad. Esperamos que te hagan pensar y te motiven para realizar eso que
                quizás estabas deseando hacer pero que no encontrabas las fuerzas, o quizás te cambien la forma de
                pensar en algunas cosas.</p>
        </div>
        <div  *ngFor="let video of videos" class="col-lg-4 col-sm-6">
            <div class="video-cont">
                <a (click)="getVideoModal(video)" class="video-imagen" [ngStyle]="{background: 'url(assets/images/videos/' + video?.thumbnail + ')'}">
                    <img src="https://mts.org.mx/assets/images/ico-play.svg" width="46">
                </a>
                <div class="video-descripcion">
                    <div class="video-texto">
                        <p class="video-titulo">
                            {{ video.titulo }}
                        </p>
                        <span class="link-extra" [innerHTML]="video?.extra"> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
    <div class="modal-dialog modal-video">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="lgModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <iframe class="video-frame"  [src]="getVideoIframe(videoModal)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <!-- <iframe [src]="getVideoIframe()" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  -->
            </div>
        </div>
    </div>
</div>