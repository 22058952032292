import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { InicioComponent } from "./pages/inicio/inicio.component";
import { LibrosComponent } from "./pages/libros/libros.component";
import { LibroComponent } from "./pages/libro/libro.component";
import { GurusComponent } from "./pages/gurus/gurus.component";
import { GuruComponent } from "./pages/guru/guru.component";
import { PeliculasComponent } from "./pages/peliculas/peliculas.component";
import { PeliculaComponent } from "./pages/pelicula/pelicula.component";
import { TecnicasDeMeditacionComponent } from "./pages/tecnicas-de-meditacion/tecnicas-de-meditacion.component";
import { MeditacionComponent } from "./pages/meditacion/meditacion.component";
import { DestinosMagicosComponent } from "./pages/destinos-magicos/destinos-magicos.component";
import { VideosComponent } from "./pages/videos/videos.component";
import { DestinoComponent } from './pages/destino/destino.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { AfiliateComponent } from './pages/afiliate/afiliate.component';
import { FrasesComponent } from './pages/frases/frases.component';
import { MeditacionesGuiadasComponent } from './pages/meditaciones-guiadas/meditaciones-guiadas.component';
import { NotasComponent } from './pages/notas/notas.component';
import { NotaComponent } from './pages/nota/nota.component';
import { AvisoLegalComponent } from './pages/aviso-legal/aviso-legal.component';
import { EventoComponent } from "./pages/evento/evento.component";
import { AvisoPrivacidadComponent } from "./pages/aviso-privacidad/aviso-privacidad.component";
import { GraciasAfiliateComponent } from "./pages/gracias-afiliate/gracias-afiliate.component";
import { GraciasContactoComponent } from "./pages/gracias-contacto/gracias-contacto.component";
import { GraciasEventoComponent } from "./pages/gracias-evento/gracias-evento.component";

const routes: Routes = [
  { path: "", component: InicioComponent },
  { path: "libros", component: LibrosComponent },
  { path: "libro/:id/:titulo", component: LibroComponent },
  { path: "gurus", component: GurusComponent },
  { path: "guru/:id/:nombre", component: GuruComponent },
  { path: "peliculas", component: PeliculasComponent },
  { path: "pelicula/:id/:titulo", component: PeliculaComponent },
  { path: "tecnicas-de-meditacion", component: TecnicasDeMeditacionComponent },
  { path: "meditacion/:id/:titulo", component: MeditacionComponent },
  { path: "destinos-magicos", component: DestinosMagicosComponent },
  { path: "videos", component: VideosComponent },
  { path: "destino/:id/:titulo", component: DestinoComponent  },
  { path: "contacto", component: ContactoComponent },
  { path: "afiliate", component: AfiliateComponent  },
  { path: "frases", component: FrasesComponent },
  { path: "meditaciones-guiadas", component: MeditacionesGuiadasComponent },
  { path: "notas", component: NotasComponent },
  { path: "nota/:id/:titulo", component: NotaComponent },
  { path: "aviso-legal" , component: AvisoLegalComponent },
  { path: "evento/:id/:titulo", component: EventoComponent},
  { path: "aviso-de-privacidad", component: AvisoPrivacidadComponent },
  { path: "gracias-afiliate", component: GraciasAfiliateComponent },
  { path: "gracias-contacto", component: GraciasContactoComponent },
  { path: "gracias-evento", component: GraciasEventoComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules
})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {}
