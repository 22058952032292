<section class="section-titulo ">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                <h1 class="titulo-pagina">Gurús</h1>
                <p class="titulo-descripcion">
                    Un verdadero Gurú es como el océano, está disponible para lo que se necesite. Depende de cada uno de
                    nosotros si lo aprovechamos para mirarlo desde lejos, mojarnos los pies o sumergirnos en las
                    profundidades y encontrar grandes tesoros.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="guru-section pt-5">
    <div class="container pt-5">
        <div class="row">
            <div class="col-md-6">
                <img class="guru-foto-grande" src="assets/images/gurus/{{guru?.foto}}" width="100%">
            </div>
            <div class="col-md-6">
                <p class="guru-titulo">Guru</p>
                <p class="guru-nombre">{{ guru?.nombre }}</p>
                <a href="{{guru?.url}}" class="guru-link">{{ guru?.sitio }}</a>
                <br>
                <div class="guru-descripcion" [innerHTML]="guru?.descripcion"> </div>
                <!--
                <div class="guru-redes">
                    <a href=""><img width="20" src="https://mts.org.mx/assets/images/ico-guru-facebook.svg"> </a>
                    <a href=""><img width="20" src="https://mts.org.mx/assets/images/ico-guru-tweeter.svg"> </a>
                    <a href=""><img width="20" src="https://mts.org.mx/assets/images/ico-guru-pinterest.svg"> </a>
                </div>
                -->
            </div>

        </div>
    </div>
</section>
<section>
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-lg-6">
                <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
                <h3 class="titulo">{{ guru?.titulo1 }}</h3>
                <div class="pleca" style="margin: 0 0 20px;"></div>
                <div [innerHTML]="guru?.contenido1"> </div>
                
                <br>
                
                <iframe  class="video-youtube" [src]="getVideoIframe()" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <!-- <iframe class="video-frame"  [src]="getVideoIframe(videoModal)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
            </div>
            <div class="col-lg-6">
                <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
                <h3 class="titulo">{{ guru?.titulo2 }} </h3>
                <div class="pleca" style="margin: 0 0 20px;"></div>
                <div [innerHTML]="guru?.contenido2"> </div>
            </div>

        </div>
    </div>
</section>
<section style="background: #ffffff;">

    <div class="container pt-5 pb-5" #comentar>
        <div class="row">
            <div class="col-lg-6 col-md-8"  >
                <h3 class="titulo">Comenta cual fue tu experiencia con este Guru.</h3>
                
                
                <!-- FIN RATING  -->
                <p class="mt-4 "><strong>Comentarios</strong></p>
                <hr width="100%">
        
                <form ngNativeValidate #form="ngForm" (ngSubmit)="sendComentario(form)" class="row">
                    <div class="form-group col-12">
                        <label for="Nombre">Nombre</label>
                        <input [(ngModel)]="Nombre" name="Nombre" class="form-control" id="Nombre" type="text"  required>
                    </div>
                    <div class="form-group col-12">
                        <label for="Comentario">Dejanos tus comentarios</label>
                        <textarea [(ngModel)]="Comentario" rows="5" id="Comentario" name="Comentario" class="form-control" required></textarea>
                    </div>
                    <div class="text-center col-12">
                        <button type="submit" class="btn btn-uno">
                            Enviar
                        </button>
                    </div>
                </form>
    
            <div class="comentarios">
                <div class="be-comment-block">
                    <h1 class="comments-title">Comentarios ({{totalcomentarios}})</h1>
                    <div  *ngFor="let ComentarioLib of ComentariosLib" class="be-comment">
                        <div class="be-img-comment">
                            <a href="blog-detail-2.html">
                                <img src="https://mts.org.mx/assets/images/avatar.jpg" alt="" class="be-ava-comment">
                            </a>
                        </div>
                        <div class="be-comment-content">
    
                            <span class="be-comment-name" [innerHTML]="ComentarioLib.nombre" >
                            
                            </span>
                            <span class="be-comment-time">
                                <i class="fa fa-clock-o"></i>
                                {{ ComentarioLib.fecha | date:'mediumDate' }}      
                            </span>
    
                            <div class="be-comment-text" > 
                                <p [innerHTML]="ComentarioLib.comentario"></p>
                                <span (click)="like(ComentarioLib)" style="cursor: pointer;width: 40px;height: 20px;"><img  src="assets/images/like.svg" width="20">   {{ ComentarioLib.megusta }}    </span>
                                <span (click)="dislike(ComentarioLib)" style="cursor: pointer;width: 40px;height: 20px;"><img  src="assets/images/dislike.svg" width="20">  {{ ComentarioLib.noMeGusta  }}    </span>
    
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</section>