<nav class="navbar navbar-expand-xl navbar-dark ">
    <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navbarResponsive"
        aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" href="#">
        <img src="assets/images/logo-mts.svg" routerLink="" alt="logo MTS">
    </a>
    
    <!-- --------- IDIOMA  ----------------- -->
    <div class="idioma movil">
        <a class="active">ES</a>
        /
        <a (click)="ChangueLanguage()" >EN</a>
    </div>
    <!-- --------- IDIOMA FIN  ----------------- -->
    <div class="collapse navbar-collapse justify-content-md-center" [ngClass]="{ show: navbarOpen }">
        <ul class="navbar-nav">
           
            <li class=" nav-item">
                <a class="nav-link" routerLink="/libros" (click)="toggleNavbar()">Libros</a>
            </li>
            <li class=" nav-item">
                <a class="nav-link" routerLink="/peliculas" (click)="toggleNavbar()">Películas</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/gurus" (click)="toggleNavbar()">Gurús</a>
            </li>
            
            <li class="btn-group nav-item" dropdown>
                <a id="button-basic" dropdownToggle type="button" class="nav-link dropdown-toggle"
                        aria-controls="dropdown-basic">
                 Meditación 
                </a>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                    role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item" routerLink="/tecnicas-de-meditacion" (click)="toggleNavbar()">Técnicas de meditación</a></li>
                  <li role="menuitem"><a class="dropdown-item" routerLink="/meditaciones-guiadas" (click)="toggleNavbar()">Meditaciones guiadas</a></li>
                </ul>
            </li>
            <!--
             <li class="nav-item">
                <a class="nav-link" routerLink="/evento/1/meditacion-por-la-unidad" (click)="toggleNavbar()">Eventos</a>
            </li>
             -->    
            <li class=" nav-item nav-item-logo">
                <a class="nav-link" routerLink="" (click)="toggleNavbar()"> <img src="
                                    https://mts.org.mx/assets/images/logo-mts.svg"> </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/destinos-magicos" (click)="toggleNavbar()">Destinos mágicos</a>
            </li>
            
           
           
            <li class=" nav-item">
                <a class="nav-link" routerLink="/videos" (click)="toggleNavbar()">Videos</a>
            </li>
            
            <li class="nav-item">
                <a class="nav-link" routerLink="/frases" (click)="toggleNavbar()">Frases</a>
            </li>
            
            <li class=" nav-item">
                <a class="nav-link" routerLink="/contacto" (click)="toggleNavbar()">Contáctanos</a>
            </li>
            
            
        </ul>
    </div>
    <!-- --------- IDIOMA  ----------------- -->
    <div class="idioma desktop">
        <a class="active">ES</a>
        /
        <a (click)="ChangueLanguage()" >EN</a>
    </div>
    <!-- --------- IDIOMA FIN  ----------------- -->
    
</nav>