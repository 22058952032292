import { Component, OnInit } from "@angular/core";
import { Environment } from 'src/app/Globals';


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  isOpen = false;
  isCollapsed = false;
  navbarOpen = null;
  path: string;

  constructor(
    public environment: Environment
  ) {
   
  }

  ngOnInit() {}

  onNavbarMovil() {
    this.navbarOpen = false;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ChangueLanguage(){
    this.path = this.environment.pathActual;
    this.path = "https://mts.org.mx/en"+this.path
    console.log(this.path)
    window.location.href = this.path
  }
}
