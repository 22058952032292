import { Component, OnInit, ViewChild } from "@angular/core";
import { LibrosService } from "../../services/libros.service";
import { MtsService } from "../../services/mts.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from '@angular/forms';
import { BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';

@Component({
  selector: "app-libros",
  templateUrl: "./libros.component.html",
  styleUrls: ["./libros.component.css"]
})
export class LibrosComponent implements OnInit {
  modalRef: BsModalRef;
  libros = [];
  Nombre: string
  Correo: string
  Comentario: string
  NombreLibro: string
  Autor: string
  ComentarioRecomendacion: string
  path: string;
  @ViewChild('lgModal') template: ModalDirective


  constructor(
    private librosService: LibrosService,
    private router: Router,
    private toastr: ToastrService,
    private activeroute: ActivatedRoute,
    private mtsService : MtsService,
    private _urlcanonicaservice: UrlcanonicaService,
    public environment: Environment,
    public meta: Meta, 
    public title: Title
    ) {
      this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Libros que cambiaran tu vida │ MTS");
      meta.updateTag({
        name: "description",
        content: "¿Alguna vez has leído un libro que te impactó tanto como para cambiar tu vida? ¿Uno que ha hecho que desde entonces todo sea diferente?"
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
    this.librosService.getLibros().subscribe((resp: any) => {
      resp.forEach((value, index) => {
        this.mtsService.votos(value.idTipo).subscribe((res: any)=> {
            value.rating = Math.round(res * 10) / 10;;
        })
      })
      resp.reverse()
      this.libros = resp;  
    });
    
  }
  newstr: any;


  ngOnInit() {
    
  }


  closeModal() {
    this.template.hide()
  }
  
  openModal() {
    this.template.show()
  }

  sendRecomendacion(f: NgForm){
    this.mtsService.postRecomendacionLibro(f.value).subscribe(
        resp => {
            this.closeModal();
            f.reset();
            this.toastr.success('Tú recomendacion se envio correctamente');
            //aqui ya realizo comentario ¿ Que haremos ?
        },
        err => {
        console.log(err)
        }
    );
  }

  getLibroUrl(libro: any) {
    this.newstr = libro.titulo.replace(/\s+/g, '-').toLowerCase();
    let reemplazarAcentos = function(cadena) {
        let chars = {
            "á": "a",  ":": "",  ";": "", "é": "e", "í": "i", "ó": "o", "ú": "u", "!": "", "à": "a",
            "è": "e", "ì": "i", "ò": "o", "ù": "u", "ñ": "n", ".": "", "¡": "", "Á": "A", "É": "E", "Í": "I",
            "Ó": "O", "Ú": "U", ",": "", "¿": "", "?": "", "À": "A", "È": "E", "Ì": "I", "Ò": "O", "Ù": "U", "Ñ": "N"}
        let expr = /[áàéèíìóòúùñ:;,¿?¡!]/ig;
        let res = cadena.replace(expr, function(e) {
            return chars[e]
        });
        return res;
    }
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(["/libro/" + libro.id + "/" + this.newstr]);
  }
}
