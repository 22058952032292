import { Component, OnInit } from '@angular/core';
import { NotasService } from '../../services/notas.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { Environment } from 'src/app/Globals';


@Component({
  selector: 'app-nota',
  templateUrl: './nota.component.html',
  styleUrls: ['./nota.component.css']
})
export class NotaComponent implements OnInit {
  nota: any
  notas: any[]
  idnotshow: any
  newstr: any;
  path: string;

  constructor(
    private router: Router,
    private notasService: NotasService,
    private activatedRoute: ActivatedRoute,
    public environment: Environment,
    private _sanitizer: DomSanitizer,
    private _urlcanonicaservice: UrlcanonicaService,
    public meta: Meta, 
    public title: Title

  ) {
    this.path = this.activatedRoute.snapshot['_routerState'].url;
    this.environment.pathActual=this.path;
    const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Técnicas de meditación │ MTS");
      meta.updateTag({
        name: "description",
        content: "Estudios demuestran que meditar aumenta las emociones positivas y el nivel de satisfacción con nuestra vida, a la vez que disminuye el estrés, la ansiedad y la depresión."
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      }); 
   }

  ngOnInit()  {
    this.activatedRoute.params.subscribe(param => {
      this.notasService.getNotas().subscribe((resp: any) =>{
        this.idnotshow = param.id
        this.nota = resp.find((nota: any) => nota.id === parseInt(param.id))
      } )
      this.notasService.getNotas().subscribe((respdos: any) => {
      
        respdos.reverse()
        var removeIndex = respdos.map(function(item) { return item.id; }).indexOf(parseInt(this.idnotshow));
        respdos.splice(removeIndex, 1);
        this.notas = respdos
      })
    })
  }
  getNotaUrl(nota: any) {
    this.newstr = nota.titulo.replace(/\s+/g, '-').toLowerCase();
    let reemplazarAcentos=function(cadena)
    {
      let chars={
        "á":"a", ":":"",  ";":"", "é":"e", "í":"i", "ó":"o", "ú":"u", "!":"",
        "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n", ".":"", "¡":"",
        "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U", ",":"", "¿":"", "?":"",
        "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"}
      let expr=/[áàéèíìóòúùñ:;,¿?¡!]/ig;
      let res=cadena.replace(expr,function(e){return chars[e]});
      return res;
    }
    this.newstr = reemplazarAcentos(this.newstr);
    this.router.navigate(['/nota/' + nota.id + '/' + this.newstr]);
    this.getNota(nota.id);
  }
  getNota(id: number){
    this.activatedRoute.params.subscribe(param => {
      this.notasService.getNotas().subscribe((resp: any) => {
        this.idnotshow = param.id
        this.nota = resp.find((nota: any) => nota.id === parseInt(param.id))
      })
      this.notasService.getNotas().subscribe((respdos: any) => {
      })
    })
    }

}
