import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MtsService } from "../../services/mts.service";  
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { Meta, Title } from "@angular/platform-browser";
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment } from 'src/app/Globals';
import {AbstractControl, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

// import Validation from './utils/validation';

@Component({
  selector: 'app-afiliate',
  templateUrl: './afiliate.component.html',
  styleUrls: ['./afiliate.component.css']
})
export class AfiliateComponent implements OnInit {
  Nombre: string
  Email: string
  Estado: string
  Telefono: string
  FechaNacimiento: Date
  minDate: Date;
  maxDate: Date;
  path: string;
  submitted = false;
  emailPattern = "^[^ ]+@[^ ]+\.[a-z]{2,63}$";
  msjenvio: boolean =  false;
  msjexiste: boolean =  false;
  msjerro: boolean =  false;


  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];


  AfiliateForm: FormGroup = new FormGroup({
    phone: new FormControl(undefined),
    Estado: new FormControl(''),
    Nombre: new FormControl(''),
    Email: new FormControl(''),
    FechaNacimiento: new FormControl(''),
    acceptTerms: new FormControl(false),
  });

  constructor(
    private toastr: ToastrService,
    private localeService: BsLocaleService,
    private mtsService : MtsService,
    private _urlcanonicaservice: UrlcanonicaService,
    private activeroute: ActivatedRoute,
    public environment: Environment,
    public meta: Meta,
    private router: Router,
    private formBuilder: FormBuilder,
    public title: Title
    ) {
      const canonical = document.querySelector('link[rel="canonical"]');
      if (canonical === null) {
        this._urlcanonicaservice.addTag( { rel: 'canonical', href: 'https://mts.org.mx/'} );
      }else{
        canonical.setAttribute('href', 'https://mts.org.mx/');
      }
  
      title.setTitle("Afiliate │ MTS");
      meta.updateTag({
        name: "description",
        content: "Afíliate y se parte de esta comunidad"
      });
      meta.updateTag({
        name: "keywords",
        content:
          "Movimiento Trascendental Social"
      });


      this.localeService.use("es");
      this.minDate = new Date();
      this.maxDate = new Date();
      this.minDate.setDate(this.minDate.getDate() - 6570);
      this.maxDate.setDate(this.maxDate.getDate() - 6570);
      this.path = this.activeroute.snapshot['_routerState'].url;
      this.environment.pathActual=this.path;
  }




  ngOnInit(): void {

    this.AfiliateForm = this.formBuilder.group({
      Nombre: ['', 
        [
          Validators.required, 
          Validators.minLength(3), 
        ],
      ],
      Email: ['', 
        [
          Validators.required, 
          Validators.pattern(this.emailPattern)
        ],
      ],
      Estado: ['',
         Validators.required
      ],
      phone: [undefined, 
        Validators.required
      ],
      FechaNacimiento: ['', 
        Validators.required
      ],
      acceptTerms: [false, 
        Validators.requiredTrue
      ],
    });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.AfiliateForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.AfiliateForm.invalid) {
      return;
    }else{
      this.msjenvio = true;
      this.addAfiliado();
    }

    
  }

  onReset(): void {
    this.submitted = false;
    this.AfiliateForm.reset();
  }

  convert(str: string | number | Date) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  addAfiliado(){
    var formValue = this.AfiliateForm.value;
    formValue.FechaNacimiento = this.convert(formValue.FechaNacimiento)

    var params = {
      Nombre: formValue.Nombre,
      Email: formValue.Email,
      Estado: formValue.Estado,
      Lada: formValue.phone.dialCode,
      Telefono: formValue.phone.number.replace(/ /g, ""),
      FechaNacimiento: formValue.FechaNacimiento
    };
    console.log(params)

    this.mtsService.postAfiliacion(params).subscribe((resp: any) => {
          if(resp.ok == false){
            this.msjexiste = true;
            this.msjenvio = false;
            setTimeout(()=>{                           
              this.msjexiste = false;
            }, 5000);
          }else{
            setTimeout(()=>{                           
              this.msjenvio = false;
              this.submitted = false;
              this.AfiliateForm.reset();
              this.router.navigate(['gracias-afiliate/']);
            }, 5000);
          }
        },
        err => {
          this.submitted = false;
          this.AfiliateForm.reset();
          this.msjerro = true;
          this.msjenvio = false;
          setTimeout(()=>{                           
            this.msjerro = false;
           
          }, 5000);
        }
    );
  }
}
