<section class="section-titulo">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Destinos mágicos</h1>
                <p class="titulo-descripcion">
                    ¿Te imaginas visitar lugares mágicos del mundo que tal vez ni sabías que existían? En este planeta
                    existen lugares mágicos por doquier.
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col-lg-6 mb-5">
            <div class="banner-imagen-principal" style="background-image: url(https://mts.org.mx/assets/images/destinos/{{destino?.portada}});">
            </div> 
        </div>
        <div class="col-lg-6">
            <img class="flor mb-3" src="https://mts.org.mx/assets/images/ico-loto.svg">
            <p class="titulo-xl">{{ destino?.titulo }}</p>
            <div class="pleca"></div>
            <p class="destino-nombre"><strong>{{ destino?.lugar }}</strong><br> {{ destino?.pais }}</p>
            <a href="{{ destino?.url }}" class="link-web">{{ destino?.sitio}} </a>
            <div [innerHTML]="destino?.descripcion"></div>
        </div>
        <div class="col-12" [innerHTML]="destino?.complemento"></div>
        <br><br><br><br>
        <div class="col-12 mb-3 mt-5">
            <p class="titulo">
                También puede interesarte
            </p>
        </div>

        <a class="col-lg-3 col-md-6 " *ngFor="let destino of destinos.slice(0, 4);" (click)="getDestinoUrl(destino)" style="cursor:pointer">
            
            <div class="destino-sugerido-img" style="background-image: url(https://mts.org.mx/assets/images/destinos/{{destino?.thumbnail}});">
                <div class="destino-img-overlay"></div>
            </div>
            <div class="destino-sugerido-text">
                
                <p class="destino-sugerido-nombre"><strong>{{ destino.titulo }},<br> </strong> {{ destino?.lugar }}</p>
                <small>Ver más</small>
            </div>
        </a>
    </div>
</div>
