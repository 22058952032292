<section class="section-titulo mb-5">
    <div class="container">
        <div class="row titulo-container">
            <div class="col-lg-6 col-md-8 offset-lg-3 offset-md-2">
                <h1 class="titulo-pagina">Libros que cambiarán tu vida </h1>
                <p class="titulo-descripcion">
                    ¿Alguna vez has leído un libro que te impactó tanto como para cambiar tu vida? ¿Uno que ha hecho que
                    desde entonces todo sea diferente?
                </p>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="row">
         <!--  LIBRO  -->
        <div class="col-lg-6 ">
            <div class="row mb-5">
                <div class="col-sm-5">
                    <img class="libro-foto" src="assets/images/libros/{{ libro?.portada }}">
                </div>
                <div class="col-sm-7">
                    <h1 class="libro-nombre">{{ libro?.titulo }}</h1>
                    <p class="libro-genero text-gold">{{ libro?.genero }}</p>
                    <div class="calificacion mb-4">
                        <p><strong>{{ libro?.rating }}</strong>/5.0</p>
                        <img class="rating" src="https://mts.org.mx/assets/images/rating/{{ libro?.rating }}.svg">
                    </div>
                    <button class="btn-uno mb-5" (click)="scroll(comentar)">Dinos tu opinión</button>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <p class="titulo titulo-libros">Autor:</p>
            <p class="text-gold">{{ libro?.autor }}</p>
            <p class="titulo titulo-libros">De que trata</p>
            <div  [innerHTML]="libro?.resena"> </div>
            <p class="titulo titulo-libros">Editorial:</p>
            <p class="text-gold">{{ libro?.editorial }}</p>
        </div>
         <!-- FIN LIBRO  -->
    </div>
</div>
<section style="background-color: #EDEEEE;" class="pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-3">
                <p class="titulo">
                    También puede interesarte
                </p>
            </div>
            <!--  LIBROS SUGERIDOS  -->
            <div  *ngFor="let libro of libros" class=" col-lg-2 col-md-3 col-6">
                <a class="libro-sugerido" (click)="getLibroUrl(libro)" style="cursor:pointer">
                    <img class="libro1" src="https://mts.org.mx/assets/images/libros/{{ libro?.thumbnail }}">
                    <p class="nombre-libro1">{{ libro.titulo }}</p>
                    <img class="rating" src="https://mts.org.mx/assets/images/rating/{{ libro?.rating }}.svg">
                </a>
            </div>
            <!-- FIN LIBROS SUGERIDOS  -->
        </div>
    </div>
</section>
<div class="container pt-5 pb-3" #comentar>
    <div class="row">
        <div class="col-lg-6 col-md-8">
            <h3 class="titulo">Danos tu calificación y/o comenta que te parecio este libro.</h3>
            <!--  RATING  -->
            
            <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly" (click)="confirmSelection()"></rating>
           
            <div class="rating-estrellas">
                <pre  style="margin:15px 0;">Tu calificación: <b>{{rate}}</b>; </pre>
            </div>

           <!-- FIN RATING  -->


            <p class="mt-4 "><strong>Comentarios</strong></p>
            <hr width="100%">

            <form ngNativeValidate #form="ngForm" (ngSubmit)="sendComentario(form)" class="row">
                <div class="form-group col-12">
                    <label for="Nombre">Nombre</label>
                    <input [(ngModel)]="Nombre" name="Nombre" class="form-control" id="Nombre" type="text"  required>
                </div>
                <div class="form-group col-12">
                    <label for="Comentario">Dejanos tus comentarios</label>
                    <textarea [(ngModel)]="Comentario" rows="5" id="Comentario" name="Comentario" class="form-control" required></textarea>
                </div>
                <div class="text-center col-12">
                    <button type="submit" class="btn btn-uno">
                        Enviar
                    </button>
                </div>
            </form>

            <div class="comentarios">
                <div class="be-comment-block">
                    <h1 class="comments-title">Comentarios ({{totalcomentarios}})</h1>
                    <div  *ngFor="let ComentarioLib of ComentariosLib" class="be-comment">
                        <div class="be-img-comment">
                            <a href="blog-detail-2.html">
                                <img src="https://mts.org.mx/assets/images/avatar.jpg" alt="" class="be-ava-comment">
                            </a>
                        </div>
                        <div class="be-comment-content">

                            <span class="be-comment-name" [innerHTML]="ComentarioLib.nombre" >
                            
                            </span>
                            <span class="be-comment-time">
                                <i class="fa fa-clock-o"></i>
                                {{ ComentarioLib.fecha | date:'mediumDate' }}      
                            </span>

                            <div class="be-comment-text" > 
                                <p [innerHTML]="ComentarioLib.comentario"></p>
                                <span (click)="like(ComentarioLib)" style="cursor: pointer;width: 40px;height: 20px;"><img  src="assets/images/like.svg" width="20">  {{ ComentarioLib.megusta }}    </span>
                                <span (click)="dislike(ComentarioLib)" style="cursor: pointer;width: 40px;height: 20px;"><img  src="assets/images/dislike.svg" width="20">  {{ ComentarioLib.noMeGusta  }}    </span>

                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>