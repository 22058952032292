import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FrasesService {

  constructor( private http: HttpClient) {}
  getFrases() {
    return this.http.get("assets/data/frases.json");
  }
}
