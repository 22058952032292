import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class MtsService {

    constructor(private http: HttpClient) { }

    califica(params: any) {
        return this.http.post(`https://mts.org.mx/WebApi/MTS/api/Votacion`, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    votos(id: any) {
        return this.http.get(`https://mts.org.mx/WebApi/MTS/api/Votacion/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    getComentarios(id: any){
        return this.http.get(`https://mts.org.mx/WebApi/MTS/api/Comentarios/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    postComentarioVoto(id: any, type: any) {
        return this.http.post(`https://mts.org.mx/WebApi/MTS/api/Comentarios/votacion?id=${id}&voto=${type}`,  {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    postComentario(comentario: any) {
        return this.http.post(`https://mts.org.mx/WebApi/MTS/api/Comentarios`, comentario, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    postRecomendacionLibro(comentario: any) {
        return this.http.post(`https://mts.org.mx/WebApi/MTS/api/RecomiendaLib`, comentario, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    
    postRecomendacionPelicula(comentario: any) {
        return this.http.post(`https://mts.org.mx/WebApi/MTS/api/RecomiendaPeli`, comentario, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    postContacto(comentario: any) {
        return this.http.post(`https://mts.org.mx/WebApi/MTS/api/Contacto`, comentario, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    postAfiliacion(comentario: any) {
        return this.http.post(`https://mts.org.mx/WebApi/MTS/api/Afiliate`, comentario, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
   
}
